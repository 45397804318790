import React, { Component } from "react";
import { Link} from "react-router-dom";
import {
  Col,
  Row,
} from "react-bootstrap";
import { APP_OPEN_DATE, DEADLINE, ANNOUNCE_RESULTS_DATE } from "../constants";
import { format } from 'date-fns';

export default class Page6 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      scholars: [],
      edgeHistories: [],
      selectedScholarIndex: 0,
      selectedScholars: [],
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.redirect) {
      return <Link to={this.state.redirect} />;
    }
    return (
      <div className="justify-content-center">
        <Row className="justify-content-center" style={{ marginTop: "5em" }}>
          <Col md={6} >
            <h2 className="font-weight-bold">Thank you for your submission!</h2>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "1em" }}>
          <Col md={6}>
            <p>Scholarship recipients will be announced during the NESS Symposium at the end of  May 2025, with winner packets distributed at the end of October (once registration is confirmed).
</p>
            <p>
              For any questions or inquiries, please contact
              scholarship@nestat.org
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "1em" }}>
          <Col md={6}>
            <p>
              Return to <a href="https://nestat.org">nestat.org</a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}
