import React from 'react'

export default function Eligibility() {
  return (
    <div>
         <p>
                This scholarship is for underrepresented groups in statistics
                and data science. This is defined by comparing the population
                estimates from the Census Bureau to the percents of degrees from
                the National Center for Education Statistics (NCES) . This tells
                us that the following groups are underrepresented in the fields
                of statistics and data science in 2023 :
              </p>
              <ul>
                <li>American Indian/Alaskan Native (1.3% vs 0.2%) </li>
                <li>Black/African American (13% vs 4%) </li>
                <li>Hispanic (19% vs 9%) </li>
                <li>Native Hawaiian or Pacific Islander (0.3% vs 0.1%)) </li>
              </ul>
    </div>
  )
}
