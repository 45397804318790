import React from "react";
import { Container, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import dotenv from "dotenv";
// const { fromTemporaryCredentials } = require('@aws-sdk/credential-providers');
// const { STSClient } = require('@aws-sdk/client-sts');
dotenv.config();
var bucketName = "nextgen-scholarship-w2s";
// const { S3Client, ListObjectsV2Command } = require('@aws-sdk/client-s3');
let s3;

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
//   region: "us-east-1",
// });

export default function Awardees() {
  const [list, setList] = useState(null);
  const [items, setItems] = useState([]);
  function onDocumentLoadSuccess(pdf) {
    // setNumPages(pdf.numPages);
  }

  function downloadFile(item) {
    s3.getObject({ Bucket: bucketName, Key: item.Key }, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        const blob = new Blob([data.Body], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.Key;
        link.click();
      }
    });
  }

  function deleteItem(item) {
    // var confirm = window.confirm("Are you sure you want to delete this item?");
    // if (!confirm) {
    //   return;
    // }

    // s3.deleteObject({ Bucket: bucketName, Key: item.Key }, function (err, data) {
    //   if (err) {
    //     console.log(err, err.stack);
    //   } else {
    //     console.log(data);
    //     s3.listObjectsV2({ Bucket: bucketName }, function (err, data) {
    //         if (err) {
    //           console.log(err, err.stack);
    //         } else {
    //           console.log(data);
    //           setItems(data.Contents);
    //         }
    //       });
    //   }
    // });
  }

  useEffect(() => {
    // s3 = new S3Client({
    //   // region: 'us-east-1',
    //   // credentials: fromTemporaryCredentials({
    //   //   client: new STSClient({ region: 'us-east-1' }),
    //   //   clientConfig: {region: 'us-east-1'},
    //   //   params: () => ({
    //   //     RoleArn: 'arn:aws:iam::346167235043:role/ness-s3-full-access-role',
    //   //   }),
    //   // }),
    //   // sslEnabled: false,
    //   // s3ForcePathStyle: true,
    //   // signatureVersion: 'v4',
    // });
    // console.log(s3)
    // const fetchData = async () => {
    //   const command = new ListObjectsV2Command({ Bucket: bucketName });
    //   const response = await s3.send(command);
    //   console.log(response);
    //   setItems(response.Contents);
    // }
    // fetchData().catch(console.error);
  }, []);

  return (
    <Container style={{ height: "100vh" }}>
      <h1>Awardees</h1>

      <h2>W-9 Forms</h2>

      <a href={list} target="_blank" rel="noreferrer">
        {list}
      </a>

      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Document</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => (
              <tr key={i}>
                <td>{item.Key}</td>
                <td>{item.LastModified.toString()}</td>
                <td><Button onClick={() => {downloadFile(item)}}>Download</Button></td>
                <td><Button variant="danger" onClick={() => {deleteItem(item)}}>Delete</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}
