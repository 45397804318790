import React, { Component } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import SimpleReactValidator from "simple-react-validator";
import {
  Container,
  Col,
  Form,
  Row,
  Button,
  InputGroup,
  Image,
  FormControl,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import DynamicSelect from "./formControlSelect.component";
import Intro from "./intro.component";
import Essay from "./essay.component";
import Page2 from "./page2.component";
import Page4 from "./page4.component";
import Page5 from "./page5.component";
import Page6 from "./page6.component";
import Error from "./error.component";
import AsyncSpinner from "./asyncspinner.component";
import banner from "../images/banner.51132ee6.png";
import {   ANNOUNCE_RESULTS_DATE } from "../constants";
import { format } from 'date-fns';
export default class Index extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        confirmEmail: {
          message: "Confirm Email must match Email",
          rule: (val, params, validator) => {
            return (
              val.toLowerCase() ===
              params.map((p) => p.toLowerCase()).indexOf(val) >= 0
            );
          },
        },
      },
    });
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.firstStep = this.firstStep.bind(this);
    this.selectList = this.selectList.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleListChange = this.handleListChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.onRaceChange = this.onRaceChange.bind(this);
    this.onOtherRace = this.onOtherRace.bind(this);
    this.onEthnicityChange = this.onEthnicityChange.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onOtherMajor = this.onOtherMajor.bind(this);
    this.onTypeaheadChange = this.onTypeaheadChange.bind(this);
    this.onChangeDiscoveryMode = this.onChangeDiscoveryMode.bind(this);
    this.onCourseworkAdd = this.onCourseworkAdd.bind(this)
    this.onCourseworkDelete = this.onCourseworkDelete.bind(this)

    this.state = {
      submitting: false,
      step: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      dob: new Date(2005, 0, 1, 1, 0, 0, 0),
      dobDefault: new Date("December 17, 2004 03:24:00"),
      dobBack: null,
      race: [],
      ethnicity: null,
      raceOptionsFirst: ["Hispanic or Latino", "Not Hispanic or Latino"],
      raceOptionsSecond: [
        "American Indian or Alaska Native",
        "Asian",
        "Black or African American",
        "Native Hawaiian or Pacific Islander",
        "White",
      ],
      citizenshipStatus: "",
      discoveryMode: "",
      educationStatus: "",
      creditHours: 0,
      gpa: "",
      gpaOutof: "",
      email: "",
      confirmEmail: "",
      major: "",
      majors: [],
      essay: "",
      notes: "",
      submissionDate: null,
      files: [],
      extracurriculars: [],
      work: [],
      projects: [],
      leadership: [],
      awards: [],
      coursework: [],
      fileNames: [],
      fileURLs: [],
      certify: null,
      error: null,
      existingApp: false,
      w9: null,
      testing: false,
      selectOptions: [
        {
          Race: [],
          US_citizenship_status: [],
          Current_education_status: [],
          State: [],
          Country: [],
        },
      ],
    };
  }

  componentDidMount() {}

  selectList(selectID, name, selectOptions, onChange, selected) {
    return (
      <DynamicSelect
        id={selectID}
        name={name}
        options={selectOptions}
        onSelectChange={onChange}
        value={selected}
      />
    );
  }

  handleChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  handleRadioChange(e){
    let val = e.target.value === "true" ? true: false
    this.setState({[e.target.name]: val})
  }

  handleSelectChange(e) {
    this.setState({ [e.target.name]: e });
  }

  handleDateChange(e) {
    if (Date.parse(e.target.value)) {
      // var date = new Date(
      //   doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
      // );
      // this.setState({ dobBack: date, dob: e.target.value });
      this.setState({[e.target.name]: e.target.value})
    }
  }

  handleListChange(updatedList, name) {
    // this.setState({[name]: updatedList})
    if(updatedList)
    this.setState({ [name]: updatedList });
  }

  onEthnicityChange(e) {
    this.setState({ ethnicity: e.target.value });
  }

  onRaceChange(e) {
    var races = this.state.race;
    if (races.indexOf(e.target.value) >= 0) {
      races.splice(races.indexOf(e.target.value), 1);
    } else {
      races.push(e.target.value);
    }
    this.setState({ race: races });
  }

  onOtherRace(e) {
    this.setState({ otherRaceValue: e.target.value });
  }

  onOtherMajor(e) {
    this.setState({ major: e.target.value });
  }

  onChangeDiscoveryMode(e) {
    this.setState({ discoveryMode: e.target.value });
  }

  onEmailBlur(e) {
    axios
      .get("/students/hasExistingApplication", {
        params: { email: this.state.email.toLowerCase() },
      })
      .then((res) => {
        this.setState({ existingApp: res.data > 0 });
      });
  }

  onTypeaheadChange(majors) {
    this.setState({ majors: majors });
  }

  onCourseworkAdd(e){
    let temp = this.state.coursework;
    temp.push(e)
    this.setState({coursework: temp})
  }

  onCourseworkDelete(e){
    let temp = this.state.coursework;
    temp.splice(temp.indexOf(e),1)
    this.setState({coursework: temp})
  }

  prevStep() {
    this.validator.purgeFields();
    this.setState({ step: this.state.step - 1 });
    window.scrollTo(0, 0);
    this.validator.hideMessages();
    this.forceUpdate();
  }

  nextStep() {
    if (this.validator.allValid() || this.state.testing) {
      this.validator.purgeFields();
      window.scrollTo(0, 0);
      this.setState({ step: this.state.step + 1 });
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  }

  firstStep() {
    this.setState({ step: this.state.step - 4 });
    window.scrollTo(0, 0);
  }

  submitForm() {

    this.setState({submitting: true});

    if (this.state.otherRaceValue) {
      var arr = this.state.race;
      arr.push(this.state.otherRaceValue);
      this.setState({ race: arr });
    }

    var requestObjects = this.state.files.map((file) => {
      var id = uuidv4();
      return {
        file: file.imageFile,
        type: file.metadata.type,
        name: file.metadata.name,
        id: id,
      };
    });
    this.setState({ submissionDate: new Date() });
    let urls = [];
    var promises = [];
    requestObjects.forEach((obj) => {
      const imageData = new FormData();
      imageData.append("image", obj.file);
      imageData.append("type", obj.type);
      imageData.append("name", obj.name);
      promises.push(
        axios
          .post("/students/addTranscriptFiles", imageData)
          .then((res) => {
            axios.put(res.data, obj.file, {
              headers: {
                "Content-Type": obj.type,
              },
            }).then((res) => {
              urls.push(res.data);
            });
            // console.log(res.data);
            // const d = JSON.stringify(res.data);
            // console.log(res.data);
            // const files = this.state.fileURLs.push(d);
            // this.setState({fileURLs: files})
          })
          .catch((err) => {
            this.setState({ error: err });
          })
      );
    });

    Promise.all(promises).then((res) => {
      this.setState({ fileURLs: urls });
      axios
        .post("/students/add", this.state)
        .then((res) => {
          emailjs.init("user_cz5tCp9b83ZF9q5LOF0Og");
          emailjs.send("service_y2mx9fv", "template_4hny1ir", {
            to_name: this.state.firstName,
            to_email: this.state.email,
            inform_date: format(new Date(ANNOUNCE_RESULTS_DATE),' MMMM, yyyy'), 
          });
          this.setState({submitting: false});
          this.nextStep();
        })
        .catch((err) => {
          this.setState({ error: err });
          console.log(err);
        });
    });
  }

  render() {
    return (
      <Container fluid style={{ minHeight: "100vh" }}>
        <Container fluid className="mt-0">
          <Row className="justify-content-center">
            <Image
              src={banner}
              className=""
              fluid
              style={{ paddingTop: "1em" }}
            ></Image>
          </Row>
          <Row className="justify-content-center">
            <h3
              style={{
                color: "#020E2F",
                textAlign: "left",
                marginTop: "2rem",
                marginBottom: "2rem",
                backgroundColor: "#EEE",
                padding: "15px",
                fontWeight: "bold",
                width: "100vw",
              }}
            >
              NextGen Scholarship Application
            </h3>
          </Row>
          {this.state.step === 0 && <Intro nextStep={this.nextStep}></Intro>}
          {this.state.step === 1 && (
            <Row className="justify-content-center">
              <Col md={6}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Form.Row>
                    <Form.Group as={Col}>
                      <h6 className="font-weight-bold">Personal Information</h6>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formFirstName" as={Col}>
                      <Form.Label className="firstName">
                        First Name<em style={{ color: "red" }}>&#42;</em>
                      </Form.Label>
                      <FormControl
                        className="firstNameInput"
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        aria-label="First Name"
                        aria-describedby="First Name"
                        onChange={this.handleChange}
                        value={this.state.firstName}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "FirstName",
                          this.state.firstName,
                          "required|alpha_space"
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formMiddleName" as={Col}>
                      <Form.Label>Middle Name</Form.Label>
                      <FormControl
                        type="text"
                        placeholder="Middle Name"
                        name="middleName"
                        aria-label="Middle Name"
                        aria-describedby="Middle Name"
                        onChange={this.handleChange}
                        value={this.state.middleName}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formLastName" as={Col}>
                      <Form.Label>
                        Last Name<em style={{ color: "red" }}>&#42;</em>
                      </Form.Label>
                      <FormControl
                      className="lastName"
                        type="text"
                        placeholder="Last Name"
                        required
                        name="lastName"
                        aria-label="Last Name"
                        aria-describedby="Last Name"
                        onChange={this.handleChange}
                        value={this.state.lastName}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "LastName",
                          this.state.lastName,
                          "required|alpha_space"
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBday" as={Col}>
                      <Form.Label>
                        Date of Birth
                        <em style={{ color: "red" }}>&#42;</em>
                      </Form.Label>
                      <FormControl
                        className="dob"
                        type="date"
                        placeholder="mm/dd/yyyy"
                        required
                        name="dob"
                        aria-label="mm/dd/yyyy"
                        aria-describedby="mm/dd/yyyy"
                        onChange={this.handleDateChange}
                        value={this.state.dob}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "DateOfBirth",
                          this.state.dob,
                          "required"
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formEmail" as={Col}>
                      <Form.Label>
                        Email<em style={{ color: "red" }}>&#42;</em>
                      </Form.Label>
                      <FormControl
                        className="email"
                        type="text"
                        placeholder="Email"
                        required
                        name="email"
                        aria-label="Email"
                        aria-describedby="Email"
                        onChange={this.handleChange}
                        onBlur={this.onEmailBlur}
                        value={this.state.email}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "Email",
                          this.state.email,
                          "required|email"
                        )}
                      </div>
                      {this.state.existingApp && (
                        <div className="text-danger">
                          There is an existing submission using this email.
                          Contact scholarship@nestat.org for help.
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formConfirmEmail" as={Col}>
                      <Form.Label>
                        Confirm Email<em style={{ color: "red" }}>&#42;</em>
                      </Form.Label>
                      <FormControl
                        className="emailConfirm"
                        type="text"
                        placeholder="Confirm Email"
                        required
                        name="confirmEmail"
                        aria-label="EmailConfirm"
                        aria-describedby="EmailConfirm"
                        onChange={this.handleChange}
                        value={this.state.confirmEmail}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "confirmEmail",
                          this.state.confirmEmail,
                          `required|in:${this.state.email}`,
                          { messages: { in: "Emails need to match!" } }
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="w9" as={Col}>
                      <Form.Label className="mr-5">
                        If selected, will you be able to provide a valid W-9?<em style={{ color: "red" }}>&#42;</em>
                      </Form.Label>
                      <Form.Check name="w9" required inline type="radio" id="w9-yes" label="Yes" onChange={this.handleRadioChange} value={true} >

                      </Form.Check>
                      <Form.Check name="w9"  required inline type="radio" id="w9-no" label="No" onChange={this.handleRadioChange} value={false} >

                      </Form.Check>
                      <div className="text-danger">
                        {this.validator.message(
                          "ConfirmW9",
                          this.state.w9,
                          "required"
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formDiscovery" as={Col}>
                      <Form.Label>
                        How did you hear about the NextGen Scholarship?
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          defaultValue="Select"
                          onChange={this.onChangeDiscoveryMode}
                        >
                          <option value="Default">Select</option>
                          <option value="Data Science Day">
                            Data Science Day
                          </option>
                          <option value="List of Scholarships">
                            List of Scholarships
                          </option>
                          <option value="NESS Website">NESS Website</option>
                          <option value="Social Media">Social Media</option>
                          <option value="Friend or Colleague">
                            Friend or Colleague
                          </option>
                          <option value="Teacher or School Guidance Services">
                            Teacher or School Guidance Services
                          </option>
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row style={{ marginTop: "6em" }}>
                    <Button
                      className="next-btn"
                      variant="outline-primary"
                      disabled={this.state.existingApp}
                      onClick={() => {
                        this.nextStep(this.validator);
                      }}
                    >
                      Next
                    </Button>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
          )}

          {this.state.step === 2 && (
            <Page2
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              selectOptions={this.state.selectOptions}
              selectList={this.selectList}
              onChange={{
                handleChange: this.handleChange,
                onRaceChange: this.onRaceChange,
                onOtherRace: this.onOtherRace,
                onEthnicityChange: this.onEthnicityChange,
              }}
              raceOptionsFirst={this.state.raceOptionsFirst}
              raceOptionsSecond={this.state.raceOptionsSecond}
              selected={{
                citizenship: this.state.citizenshipStatus,
                education: this.state.educationStatus,
                gpa: this.state.gpa,
                gpaOutof: this.state.gpaOutof,
                email: this.state.email,
                race: this.state.race,
                ethnicity: this.state.ethnicity,
                creditHours: this.state.creditHours,
              }}
              validator={this.validator}
            />
          )}

          {this.state.step === 3 && (
            <Essay
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onChange={{
                handleChange: this.handleChange,
                onOtherMajor: this.onOtherMajor,
                listChange: this.handleListChange,
                onTypeaheadChange: this.onTypeaheadChange,
              }}
              values={{
                essay: this.state.essay,
                files: this.state.files,
                major: this.state.major,
                majors: this.state.majors,
              }}
              selectList={this.selectList}
              validator={this.validator}
              testing={{testing: this.state.testing}}
            ></Essay>
          )}
          {this.state.step === 4 && (
            <Page4
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onChange={{
                handleChange: this.handleChange,
              }}
              listChange={this.handleListChange}
              values={{
                essay: this.state.essay,
                projects: this.state.projects,
                awards: this.state.awards,
                work: this.state.work,
                leadership: this.state.leadership,
                coursework: this.state.coursework,
                extracurriculars: this.state.extracurriculars,
                notes: this.state.notes,
                name: this.state.firstName + this.state.lastName,
                files: this.state.files,
              }}
              dateChange={this.handleDateChange}
              validator={this.validator}
              onCourseworkAdd={this.onCourseworkAdd}
              onCourseworkDelete={this.onCourseworkDelete}
            />
          )}

          {this.state.step === 5 && (
            <Page5
              submitting={this.state.submitting}
              nextStep={this.submitForm}
              prevStep={this.prevStep}
              firstStep={this.firstStep}
              onChange={{ handleChange: this.handleChange }}
              {...this.state}
            />
          )}

          {this.state.step === 6 && <Page6 />}

          {this.state.error && <Error></Error>}
          {this.state.submitting && <AsyncSpinner message={'Submitting...'}></AsyncSpinner>}
        </Container>
      </Container>
    );
  }
}
