import React from "react";
import { useState, useEffect } from "react";
import { Container, Form, Button, FormControl } from "react-bootstrap";
import { child, get, getDatabase, ref, set } from "firebase/database";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import * as Realm from "realm-web";

const app = new Realm.App({ id: "nextgenbackend-nshqhzi" });
const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(d.getDate()+1).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function Settings(props) {
  const [editing, setEditing] = useState(false);
  const [formState, setFormState] = useState({
    openDate: new Date(),
    closeDate: new Date(),
    resultsDate: new Date(),
    openDateBack: new Date(),
    closeDateBack: new Date(),
    resultsDateBack: new Date(),
  });

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    const Credentials = Realm.Credentials.emailPassword(
      "",
      ""
    );
    const user = await app.logIn(Credentials);
    const mongo = user.mongoClient("mongodb-atlas");
    const evals = mongo.db("Ness_Scholarships").collection("Settings");
    const result = await evals.find({});
    setFormState({
      openDate: formatDate(result[0].ApplicationOpenDate),
      closeDate: formatDate(result[0].Deadline),
      resultsDate: formatDate(result[0].AnnounceResultsDate),
      openDateBack: formatDate(result[0].ApplicationOpenDate),
      closeDateBack: formatDate(result[0].Deadline),
      resultsDateBack: formatDate(result[0].AnnounceResultsDate),
    });

  }

  function handleDateChange(e) {
    if (Date.parse(e.target.value)) {
      var doo = new Date(e.target.value);
      var date = new Date(
        doo.getTime() + Math.abs(doo.getTimezoneOffset() * 60000)
      );
      setFormState({
        [e.target.name]: date,
      });
    }
  }

  function handleChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormState({
      [e.target.name]: value,
    });
  }

  function resetEvaluators() {
    const dbRef = ref(getDatabase());
    //get list of evaluator roles
    const roles = [];
    get(child(dbRef, `evaluatorRoles/`)).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          roles.push(childSnapshot.val());
        });
        if (
          window.confirm("Are you sure you want to reset all evaluator roles?")
        ) {
          for (let i = 0; i < roles.length; i++) {
            set(child(dbRef, `evaluatorRoles/${i}/user`), "");
          }
        }
      }
    });
  }

  return (
    <div>
      <Container
        fluid
        style={{ minHeight: "100vh", padding: "0 100px 0 100px", width: "34%" }}
      >
        <h1>Admin Settings</h1>
        <br></br>
        {/* <div className="my-5"><Button>Set new App</Button></div> */}
        <Button onClick={resetEvaluators}>Reset Evaluator Roles</Button>
        {!editing && (
          <div className="my-5">
            <h3>Application Open Date</h3>
            {format(new Date(formState.openDate), "MM/dd/yyyy")}
            <br></br>
            <h3>Application Close Date</h3>
            {format(new Date(formState.closeDate), "MM/dd/yyyy")}
            <br></br>
            <h3>Results Announcement Date</h3>
            {format(new Date(formState.resultsDate), "MM/dd/yyyy")}
            <br></br>
            <Button
              className="my-5"
              onClick={() => {
                setEditing(!editing);
              }}
            >
              Edit Dates
            </Button>
          </div>
        )}

        {editing && (
          <div className="my-5">
            <div className="my-2">
              <Form.Label>
                Opening Date
                <em style={{ color: "red" }}>&#42;</em>
              </Form.Label>
              <FormControl
                className="dob"
                type="date"
                placeholder="mm/dd/yyyy"
                required
                name="openDate"
                aria-label="mm/dd/yyyy"
                aria-describedby="mm/dd/yyyy"
                onChange={handleDateChange}
                defaultValue={formState.openDateBack}
              />
            </div>
            <div>
              <Form.Label>
                Close Date
                <em style={{ color: "red" }}>&#42;</em>
              </Form.Label>
              <FormControl
                className="dob"
                type="date"
                placeholder="mm/dd/yyyy"
                required
                name="closeDate"
                aria-label="mm/dd/yyyy"
                aria-describedby="mm/dd/yyyy"
                onChange={handleDateChange}
                value={formState.closeDateBack}
              />
            </div>
            <div className="my-5">
              <Form.Label>
                Results Announcement Date
                <em style={{ color: "red" }}>&#42;</em>
              </Form.Label>
              <FormControl
                className="dob"
                type="date"
                placeholder="mm/dd/yyyy"
                required
                name="resultsDate"
                aria-label="mm/dd/yyyy"
                aria-describedby="mm/dd/yyyy"
                onChange={handleDateChange}
                value={formState.resultsDateBack}
              />
            </div>
            <Button
              className="my-5"
              onClick={() => {
                setFormState({
                  openDate: formatDate(formState.openDateBack),
                  closeDate: formatDate(formState.closeDateBack),
                  resultsDate: formatDate(formState.resultsDateBack),
                  openDateBack: formatDate(formState.openDateBack),
                  closeDateBack: formatDate(formState.closeDateBack),
                  resultsDateBack: formatDate(formState.resultsDateBack),
                });
                setEditing(!editing);
              }}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
}
